import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LegalQuotesIntro'

// markup
const LatQuotes_r = () => {
  return (
   <Layout >
      <Head title='Latin Legal Maxims, Writs and Expressions - R'/>
      <h1>Legal Latin maxims and expressions</h1>
     <h2>R</h2>
     <LatQuotesIntro />
     <p><b>Ratio est legis anima, mutata legis ratione mutatur et lex</b> - Reason is the soul of the law; when the reason of the law changes the law also is changed.</p>
<p><b>Re</b> - In the matter of.</p>
<p><b>Reprobata pecunia leberat solventem</b> - Money refused releases the debtor.</p>
<p><b>Res</b> - Matter, affair, thing, circumstance.</p>
<p><b>Res gestae</b> -  Things done.</p>
<p><b>Res integra</b> - A matter untouched (by decision).</p>
<p><b>Res inter alios acta alteri nocere non debet</b> - Things done between strangers ought not to affect a third person, who is a stranger to the transaction.</p>
<p><b>Res judicata accipitur pro veritate</b> - A thing adjudged is accepted for the truth.</p>
<p><b>Res nulis</b> - Nobody s property.</p>
<p><b>Respondeat superior</b> - Let the principal answer.</p>
<p><b>Rex est major singulis, minor universis</b> - The King is greater than individuals, less than all the people.</p>
<p><b>Rex non debet judicare sed secundum legem</b> - The King ought not to judge but according to the law.</p>
<p><b>Rex non potest peccare</b> - The King can do no wrong.</p>
<p><b>Rex nunquma moritur</b> - The King never dies.</p>
<p><b>Rex quod injustum est facere non potest</b> - The King cannot do what is unjust.</p>

   </Layout>
  )
}

export default LatQuotes_r
